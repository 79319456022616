export default {
	data: {
		showRestaurant: false
	},
	beforeMount: function () {

	},
	computed: {

	}

};